import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiCheckboxCircleLine } from "react-icons/ri"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Booking = ({location}) => {
  return (
    <Layout>
      <SEO />
      <div
        css={css`
          padding: 80px 0;
          align-items: center;
          height: 2600px;
        `}
      >
        <div
          className="embed-responsive embed-responsive-4by3"
          style={{ height: "100%" }}
        >
          <iframe
            className="embed-responsive-item"
            src={"https://www2.sakura-home.com.hk/hchoreport/" + location.search}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}
/**
const Booking = () => {
  return (
    <Layout>
      <SEO />
      <div
        css={css`
          padding: 80px 0;
          align-items: center;
          height: 2600px;
        `}
      >
        <div
          className="embed-responsive embed-responsive-4by3"
          style={{ height: "100%" }}
        >
           <iframe 
           className="embed-responsive-item"
           frameborder="0" class="frameboxlive" 
           src="" 
           height="800">

           </iframe>

        
          </div>

    }
}
        </div>
      </div>
    </Layout>
  )
}
**/


export default Booking
